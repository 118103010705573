class SideMenu {
  el;
  buttonOpen;
  buttonClose;

  constructor(sideMenu) {
    this.el = sideMenu;
    this.buttonOpen = document.querySelector(".js-button-side-menu");
    this.buttonClose = this.el.querySelector(".js-button-close");
    this.allLinks = this.el.querySelectorAll("a, button");
    this.bindListeners();
  }

  bindListeners() {
    this.buttonOpen.addEventListener("click", this.open.bind(this));
    this.buttonClose.addEventListener("click", this.close.bind(this));
    this.allLinks.forEach((link) => {
      link.setAttribute("tabindex", -1);
    });
  }

  open() {
    document.body.style.overflow = "hidden";
    this.el.classList.add("open");
    this.allLinks.forEach((link) => {
      link.setAttribute("tabindex", 0);
    });
    this.buttonOpen.setAttribute("aria-expanded", true);
    this.buttonClose.style.animation =
      "buttonRotate .5s cubic-bezier(0.25, 0.1, 0, 0.77) .25s";
    this.buttonClose.setAttribute("aria-expanded", false);
    setTimeout(() => {
      this.buttonClose.style.animation = "none";
    }, 500);
  }

  close() {
    document.body.style.overflow = "";
    this.el.classList.remove("open");
    this.allLinks.forEach((link) => {
      link.setAttribute("tabindex", -1);
    });
    this.buttonOpen.setAttribute("aria-expanded", false);
    this.buttonClose.style.animation =
      "buttonRotate .5s cubic-bezier(0.25, 0.1, 0, 0.77) reverse";
    this.buttonClose.setAttribute("aria-expanded", true);
    setTimeout(() => {
      this.buttonClose.style.animation = "none";
    }, 500);
  }
}

const sideMenu = document.querySelector(".js-side-menu");
if (sideMenu) {
  new SideMenu(sideMenu);
}
