class ItemsAccordion {
  constructor(el) {
    this.el = el;
    this.items = this.el.querySelectorAll(".items-accordion__item");
    for (let accordion of this.items) {
      new ItemsAccordionItem(accordion);
    }
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  handleResize() {
    for (let accordionItem of this.items) {
      const item = accordionItem.querySelector(".items-accordion__item-panel");
      if (accordionItem.querySelector(".items-accordion__item-button").getAttribute("aria-expanded") === "true") {
        item.style.height = 'auto';
        item.style.height = item.scrollHeight + 'px';
      }
    }
  }
}

class ItemsAccordionItem {
  constructor(el) {
    this.el = el;
    this.elButton = this.el.querySelector(".items-accordion__item-button");
    this.elButtonIcon = this.el.querySelector(".items-accordion__item-button-icon");
    this.elButtonIconSvg = this.el.querySelector(".items-accordion__item-button-icon .delete");
    this.elPanel = this.el.querySelector(".items-accordion__item-panel");
    this.elLinks = this.el.querySelector(".items-accordion__item-panel a");


    this.elButton.addEventListener(
      "click",
      function (ev) {
        let accordionExpanded =
          this.elButton.getAttribute("aria-expanded") === "true";
        if (!accordionExpanded) {
          this.elPanel.setAttribute("tabindex", 0);
          this.elLinks.setAttribute("tabindex", 0);
          this.elPanel.style.pointerEvents = "all";
          this.elPanel.style.transition = "height .25s linear, opacity .5s linear .5s";
          this.elButton.setAttribute("aria-expanded", true);
          this.elPanel.style.height = this.elPanel.scrollHeight + "px";
          this.elPanel.style.opacity = "1";
          this.el.scrollIntoView({ behavior: 'smooth', block: 'start' });

          setTimeout(() => {
            this.elButtonIconSvg.style.display = "none";
            this.elButtonIcon.style.animation = "";
          }, 250);
        } else {
          this.elPanel.setAttribute("tabindex", -1);
          this.elLinks.setAttribute("tabindex", -1);
          this.elPanel.style.pointerEvents = "none";
          this.elPanel.style.transition = "height .25s linear, opacity .2s linear";
          this.elPanel.style.opacity = "0";
          setTimeout(() => {
            this.elButton.setAttribute("aria-expanded", false);
            this.elPanel.style.height = "0";
          }, 200);
          
          setTimeout(() => {
            this.elButtonIcon.style.animation = "";
            this.elButtonIconSvg.style.display = "block";
          }, 450);
        }
      }.bind(this),
      false
    );
  }
}

const initAccordions = () => {
  const accordions = document.querySelectorAll(".js-items-accordion");
  if (accordions) {
    for (let accordion of accordions) {
      new ItemsAccordion(accordion);
    }
  }
};

initAccordions();
