class Intersect {
    constructor(el) {
      this.el = el;
      this.treshold = parseFloat(el.dataset.treshold) || 0.1;
      this.hasIntersected = false;
      this.init();
    }
  
    init() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.el.classList.add('active');
            this.hasIntersected = true;
          } else if (this.hasIntersected) {
            this.el.classList.remove('active');
            this.el.classList.add('has-intersected');
            if (this.el.dataset.once === 'true') {
              observer.unobserve(this.el);
            }
          }
        });
      }, {
        treshold: this.treshold,
      });
  
      observer.observe(this.el);
    }
  }
  
  document.addEventListener("DOMContentLoaded", () => {
    const intersectEls = Array.from(document.querySelectorAll('.intersect'));
    intersectEls.forEach((el) => {
      new Intersect(el);
    });
  });
  