class Parallax {
    constructor(containerSelector, options = {}) {
        this.containers = document.querySelectorAll(containerSelector);
        this.speed = options.speed || 0.5;
        this.init();
    }

    init() {
        window.addEventListener('scroll', this.onScroll.bind(this));
    }

    onScroll() {
        this.containers.forEach(container => {
            const parallaxImage = container.querySelector('.image');
            if (!parallaxImage || !container.classList.contains('active')) return;

            const rect = container.getBoundingClientRect();
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const offset = rect.top + scrollTop;
            const yPos = (scrollTop - offset) * this.speed;

            parallaxImage.style.transform = `translateY(${yPos}px)`;
        });
    }

    destroy() {
        this.containers.forEach(container => {
            const parallaxImage = container.querySelector('.image');
            if (parallaxImage) {
                parallaxImage.style.transform = '';
            }
        });

        window.removeEventListener('scroll', this.onScroll);
    }
}

const initParallaxForIntersectedElements = () => {
    const intersectedElements = document.querySelectorAll(".parallax-banner__image.active");

    intersectedElements.forEach(element => {
        if (!element.parallaxInstance) {
            element.parallaxInstance = new Parallax('.parallax-banner__image', { speed: 0.1 });
        }
    });

    const inactiveElements = document.querySelectorAll(".parallax-banner__image:not(.active)");
    inactiveElements.forEach(element => {
        if (element.parallaxInstance) {
            element.parallaxInstance.destroy();
            element.parallaxInstance = null;
        }
    });
};

document.addEventListener('DOMContentLoaded', initParallaxForIntersectedElements);

const observer = new MutationObserver(initParallaxForIntersectedElements);

observer.observe(document.body, {
    attributes: true,
    subtree: true,
    attributeFilter: ['class']
});